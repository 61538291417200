import '../common.css'
import * as yup from 'yup'
import { AppRoutes } from 'src/appRoutes'
import { Button, Space } from 'capricorn-ui/esm'
import { FormattedMessage, useIntl } from 'react-intl'
import { ModalRoutes } from 'src/modalRoutes'
import { useStore } from 'src/store'
import AnalyticService from 'services/AnalyticService'
import ApiService from 'services/ApiService'
import EmptyModal from 'components/modals/EmptyModal'
import LiveChat from 'components/widgets/LiveChat'
import LoadingBar from 'react-top-loading-bar'
import Notices from 'components/Notices'
import React, { FC, Suspense, lazy, useEffect, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styled from 'astroturf/react'
import useConcurrentLocation from 'src/hooks/useConcurrentLocation'
import useLocalStorage from 'react-use/esm/useLocalStorage'

const FeesTable = lazy(() => import('components/organisms/FeesTable'))

const AppContainer: FC = () => {
    const intl = useIntl()
    const location = useConcurrentLocation()

    const state = location.state as { backgroundLocation?: Location }
    const { common, auth, notice } = useStore()
    const [signedFees, setSignedFees] = useLocalStorage('signed-fees')

    const [isOpenedFeesModal, setIsOpenedFeesModal] = useState(false)
    const [isPrevLocationModal, setIsPrevLocationModal] = useState(false)

    const signFees = () => {
        setSignedFees('true')
        setIsOpenedFeesModal(false)
    }

    useEffect(() => {
        if (auth.user?.verificationStatus === 'verified' && signedFees !== 'true') {
            setIsOpenedFeesModal(true)
        }
    }, [auth.user?.verificationStatus === 'verified', signedFees !== 'true'])

    useEffect(() => {
        yup.setLocale({
            mixed: {
                required: intl.formatMessage({ defaultMessage: 'Please fill out this field' }),
                oneOf: () => {
                    return intl.formatMessage({ defaultMessage: 'Passwords must match' })
                },
            },
            boolean: {
                required: intl.formatMessage({ defaultMessage: 'Please fill out this field' }),
            },
            string: {
                required: intl.formatMessage({ defaultMessage: 'Please fill out this field' }),
                min: ({ min }) =>
                    intl.formatMessage(
                        {
                            defaultMessage: 'Field must be at least {count} characters',
                        },
                        { count: min },
                    ),
                max: ({ max }) =>
                    intl.formatMessage(
                        {
                            defaultMessage: 'Field must be no more than {count} characters',
                        },
                        { count: max },
                    ),
                oneOf: () => {
                    return intl.formatMessage({ defaultMessage: 'Passwords must match' })
                },
                length: ({ length }) =>
                    intl.formatMessage(
                        {
                            defaultMessage: 'Field must contain {count} characters',
                        },
                        { count: length },
                    ),
            },
            number: {
                min: ({ min }) =>
                    intl.formatMessage(
                        {
                            defaultMessage: 'Field must be at least {count} characters',
                        },
                        { count: min },
                    ),
            },
        })
    }, [common.locale])

    useEffect(() => {
        const pageContent = document.getElementById('page-content')
        const isModalRoute = Boolean(location.state?.backgroundLocation)

        if (!isModalRoute && !isPrevLocationModal) {
            if (pageContent) {
                pageContent.scrollTo({
                    top: 0,
                })
            }
        }

        setIsPrevLocationModal(isModalRoute)

        AnalyticService.trackPageView(location?.pathname, document.title)
    }, [location.pathname])

    useEffect(() => {
        const checkForUpdates = async () => {
            try {
                const buildInfo = await ApiService.request<{ version: string }>('/build.json', {}, 'GET', {}, {}, true)
                const isNewVersionReleased =
                    buildInfo.version.localeCompare(PACKAGE_VERSION, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    }) === 1

                if (isNewVersionReleased) {
                    notice.show(
                        {
                            title: intl.formatMessage({ defaultMessage: 'New version is available now' }),
                            message: intl.formatMessage({ defaultMessage: 'Please refresh the page' }),
                            skin: 'message',
                        },
                        'infinity',
                    )
                } else {
                    setTimeout(checkForUpdates, 30000)
                }
            } catch (e) {}
        }

        if (STAGE !== 'development' && ENV === 'browser') {
            checkForUpdates()
        }
    }, [])

    return (
        <Content theme={common.theme} loading={location.isPending}>
            {location.isPending && <LoadingBar progress={80} height={2.5} color="var(--blue-to-white)" />}
            <SkeletonTheme highlightColor="var(--bg-color-1)" color="var(--bg-color-3)">
                <AppRoutes location={state?.backgroundLocation || location} />
                <ModalRoutes />
                {/* For noRouting modals */}
                <div id="modal-portal" />
                {isOpenedFeesModal && (
                    <EmptyModal
                        onCancel={() => setIsOpenedFeesModal(false)}
                        title={<FormattedMessage defaultMessage="Your Service Rates" />}
                        maxWidth={640}
                        maxHeight="90vh"
                        noTitleBorder
                        cantDismiss
                        footer={
                            <Space justify="flex-end" align="center">
                                <div style={{ fontSize: '0.7em' }}>
                                    <FormattedMessage defaultMessage="By clicking the I Agree button you accept the terms of commission collection by the Practika Finance service." />
                                </div>
                                <Button onClick={signFees}>
                                    <FormattedMessage defaultMessage="I Agree" />
                                </Button>
                            </Space>
                        }
                    >
                        <Suspense fallback={<Skeleton count={20} />}>
                            {auth.user?.applicationInfo?.stage && (
                                <FeesTable
                                    userType={auth.user?.applicationInfo?.stage as 'business' | 'personal'}
                                    noSurface
                                />
                            )}
                        </Suspense>
                    </EmptyModal>
                )}
                {/*<AutoLogoutModal />*/}
                {(STAGE !== 'production' ||
                    auth?.user?.login === 'Media_Revolution' ||
                    auth?.user?.login === 'beldezor' ||
                    auth?.user?.login === 'shirskiy') && <LiveChat isAuth={auth.user?.id} />}
            </SkeletonTheme>
            <Notices noticesStore={notice} />
        </Content>
    )
}

const Content = styled.div<{ theme?: 'light' | 'dark'; loading?: boolean }>`
    font:
        normal 15px/1.7 'Open Sans',
        sans-serif;
    color: var(--main-text-color);

    &.loading * {
        cursor: progress !important;
    }

    &.theme-light {
        --bg-color-1: #fff;
        --bg-color-2: #f9fafb;
        --bg-color-3: #f1f4f7;
        --bg-color-4: #fafbfb;
        --bg-color-5: #fff;
        --bg-color-6: #dfe2e8;
        --main-text-color: #2f3541;
        --blue-to-white: #2281ff;
        --main-second-text-color: #b8bdc4;
        --main-third-text-color: #7e8289;

        --loader-bg-color-1: #f4f6f7;
        --loader-bg-color-2: #e5e8ee;

        --light-bg-color: #d9dde4;
        --lighter-bg-color: #e7e7e7;
    }

    &.theme-dark {
        --bg-color-1: #1d2534;
        --bg-color-2: #2f3e5b;
        --bg-color-3: #28344a;
        --bg-color-4: #1d2534;
        --bg-color-5: #28344a;
        --bg-color-6: #525f79;
        --main-text-color: #fff;
        --blue-to-white: #fff;
        --main-second-text-color: #95abd6;
        --main-third-text-color: #95abd6;

        --loader-bg-color-1: #2f3e5b;
        --loader-bg-color-2: #1d2534;

        --light-bg-color: #525f79;
        --lighter-bg-color: #525f79;
    }
`

export default AppContainer
