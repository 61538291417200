import { Checkbox } from 'capricorn-ui'
import { CheckboxProps } from 'capricorn-ui/lib/Checkbox'
import { FieldValues, useController } from 'react-hook-form'
import React from 'react'

type CapricornProps = Omit<
    CheckboxProps & {
        noErrorMessage?: boolean
    },
    'name' | 'onChange' | 'checked'
>

interface Props extends Omit<CheckboxProps, 'onChange'> {
    name: string
    control: Control<FieldValues>
    noErrorMessage?: boolean
}

const ControlledCheckbox: React.FC<Props> = ({ name, control, noErrorMessage, style, ...props }) => {
    const {
        field: { value, onChange },
        fieldState: { error },
    } = useController({
        name,
        control,
    })

    return (
        <div
            style={{
                ...style,
                marginBottom: error ? '15px' : undefined,
            }}
        >
            <Checkbox checked={value} onChange={onChange} {...props} />
            {!noErrorMessage && error && (
                <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{error.message}</div>
            )}
        </div>
    )
}

export default ControlledCheckbox
