export enum API {
    USER_SIGN_UP_INITIAL = '/user/register/initial',
    USER_SIGN_IN = '/user/login',
    USER_SIGN_OUT = '/user/logout',
    USER_FETCH = '/user/who-am-i',
    SET_PASSWORD = '/user/register/set-password',
    RESET_PASSWORD = '/user/reset-password/send-message',

    USER_CHANGE_EMAIL = '/user/profile/change-email',
    USER_CHANGE_LOCALE = '/user/profile/change-locale',
    USER_VERIFY_EMAIL = '/user/profile/verify-email',

    CHECK_OTP = '/user/check-otp',

    ACCOUNT_LIST = '/user/accounts/list',

    CARDS = '/cards1',
    CARD_LIST = '/cards1/cardlist',

    ANALYTIC = '/user/accounts/analytics',

    MASS_PAYMENT_DETAILS = '/mass-payment-details',
    HISTORY_OPERATIONS = '/document/list',

    CALC_COMISSION = '/document/calculate-commission',
    CREATE_OPERATION = '/document/create',
    CONFIRM_OPERATION = '/document/verify',
    CREATE_EXCHANGE = '/document/conversion/create',

    PROFILE_INFO = '/user/profile/info',
    USER_SETTINGS = '/user/user-settings',
    USER_SIGN_UP_PHONE_NUMBER = '/user/register/phoneNumber',
    USER_SIGN_UP_EMAIL = '/user/register/email',
    USER_SIGN_UP_LOGIN = '/user/register/login',

    TEMPLATE_LIST = '/document/templates/list',
    TEMPLATE_ADD = '/document/templates/create',
    TEMPLATE_EDIT = '/document/templates/edit',
    TEMPLATE_DELETE = '/document/templates',

    LOGIN_HISTORY = '/user/profile/login-history',

    IP_WHITELIST_LIST = '/user/profile/ip-whitelist/list',
    IP_WHITELIST_ADD = '/user/profile/ip-whitelist/add-ip',
    IP_WHITELIST_REMOVE = '/user/profile/ip-whitelist/delete',
    // Включает / Выключает весь список IP
    IP_WHITELIST_SET_USER = '/user/profile/ip-whitelist/set-user-active',
    // Включает / Выключает указанный IP
    IP_WHITELIST_SET_IP = '/user/profile/ip-whitelist/set-ip-active',

    EXCHANGE_RATES = '/currency-rates',

    HISTORY_MASS_TRANSFERS = '/document/batch/list',
    CREATE_MASS_TRANSFER = '/document/batch/create',
    SEND_OTP_MASS_TRANSFER = '/document/batch/send-otp',
    MASS_TRANSFER_INFO = '/document/batch/batch-info',
    CONFIRM_MASS_TRANSFER = '/document/batch/process',
    FEES = '/document/document-types',

    MAKE_VERIFICATION = '/user/profile/applications',

    UPLOAD_FILE = '/user/profile/files/upload',
    GET_FILE = '/user/profile/files',

    TICKETS = '/user/help-desk/tickets',
    TICKET_ATTACH = '/user/help-desk/tickets/blobs',
    GET_AGENTS = '/user/help-desk/people',

    START_AML = '/user/ondato/startAml',
    GET_AML = '/user/ondato/getForCurrentUser',
    TIME_TO_WAIT_AML = '/user/ondato/timeToWait',

    NOTICES = '/user/notices',
    WALLETS = '/user/wallets/list',
    REQUEST_IBAN = '/user/wallets/requests/create',

    TRANSACTION_ATTACH = '/document/attachment',

    GOOGLE_2FA = '/rest/2fa',
    SIGN_GOOGLE_2FA = '/rest/2faSign',

    SET_VERIFICATION_TYPE = '/user/profile/set-confirmation-type',

    GET_TOKENS = '/user/api/list-tokens',
    UPDATE_TOKEN = '/user/api/update-token',
    DELETE_TOKEN = '/user/api/delete-token',
    CREATE_TOKEN = '/user/api/create-token',

    ADD_DOCUMENT_TO_MODERATION = '/moderatedFiles/new',
    GET_DOCUMENTS_FROM_MODERATION = '/moderatedFiles/list',

    PAYMENT_DETAILS = '/internal/report/paymentDetails',

    EXCHANGE_GET_RATE = '/server/ducas/conversion/start',
}
