import { AnimatePresence, motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { Space } from 'capricorn-ui'
import { Theme } from 'src/store/commonStore'
import { callIfExist } from 'src/utils'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import CrossSvg from 'src/assets/icons/cross-thin.svg'
import LoaderSvg from 'assets/icons/loader.svg'
import MobilePageHeader from 'components/mobile/MobilePageHeader'
import React, { useEffect, useState } from 'react'
import styled from 'astroturf/react'

export interface IModalProps {
    children: React.ReactNode
    title?: React.ReactNode
    footer?: React.ReactNode
    actionOnClick?: () => void
    onDismissClick?: () => void
    maxWidth?: number
    maxHeight?: number | string
    noXPadding?: boolean
    noYPadding?: boolean
    noTitleBorder?: boolean
    noFooterBorder?: boolean
    cantDismiss?: boolean
    noRouting?: boolean
    customCross?: boolean
    fullModalLoading?: boolean
    // For dropdowns not to hide part of these
    modalContentNoOverflowAuto?: boolean
    mobileNoTransformTitleToPageHeader?: boolean
}

const Modal: React.FC<IModalProps> = ({
    title,
    children,
    onDismissClick,
    maxWidth,
    maxHeight,
    noXPadding,
    noYPadding,
    footer,
    noTitleBorder,
    noFooterBorder,
    cantDismiss,
    noRouting,
    customCross,
    fullModalLoading,
    modalContentNoOverflowAuto,
    mobileNoTransformTitleToPageHeader,
    ...rest
}) => {
    const { width: windowWidth, height: windowHeight } = useWindowSize()
    const navigate = useNavigate()
    const { theme } = useStore().common
    const [isOpen, setIsOpen] = useState(true)

    const handleClose = () => {
        if (cantDismiss) return

        setIsOpen(false)

        document.body.style.overflow = 'auto'

        setTimeout(() => {
            if (!noRouting) {
                navigate(-1)
            }
            callIfExist(onDismissClick)
        }, 300)
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        const handler = (event: KeyboardEvent) => {
            if (event.code === 'Escape' && !cantDismiss) {
                handleClose()
            }
        }

        window.addEventListener('keyup', handler)

        return function () {
            document.body.style.overflow = 'auto'
            window.removeEventListener('keyup', handler)
        }
    }, [cantDismiss])

    const handleModalContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }

    return (
        <AnimatePresence>
            {isOpen && (
                <ModalOverlay theme={theme} onClick={cantDismiss ? undefined : handleClose}>
                    <motion.div
                        drag={windowWidth <= 600 ? 'y' : false}
                        dragConstraints={{ top: 0, bottom: 0 }}
                        dragElastic={{ top: 0.05, bottom: 1 }}
                        onDragEnd={(event, info) => {
                            if (info.offset.y >= windowHeight / 3) {
                                handleClose()
                            }
                        }}
                        initial={
                            windowWidth > 600
                                ? {
                                      opacity: 0,
                                      scale: 0.75,
                                  }
                                : { y: windowHeight, opacity: 0 }
                        }
                        animate={
                            windowWidth > 600
                                ? {
                                      opacity: 1,
                                      scale: 1,
                                      transition: {
                                          ease: 'easeOut',
                                          duration: 0.15,
                                      },
                                  }
                                : {
                                      y: 0,
                                      opacity: 1,
                                      transition: {
                                          ease: 'easeOut',
                                          duration: 0.3,
                                      },
                                  }
                        }
                        exit={
                            windowWidth > 600
                                ? {
                                      opacity: 0,
                                      scale: 0.75,
                                      transition: {
                                          ease: 'easeIn',
                                          duration: 0.15,
                                      },
                                  }
                                : {
                                      y: windowHeight,
                                      opacity: 0,
                                      transition: {
                                          ease: 'easeIn',
                                          duration: 0.3,
                                      },
                                  }
                        }
                        style={{
                            width: '100%',
                            maxWidth: `${maxWidth}px`,
                            maxHeight: typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight,
                            height: '100%',
                            display: 'flex',
                            alignItems: windowWidth <= 600 ? 'flex-end' : 'center',
                        }}
                    >
                        <Container
                            style={{ maxWidth: `${maxWidth}px` }}
                            onClick={handleModalContentClick}
                            noXPadding={noXPadding}
                            noYPadding={noYPadding}
                        >
                            {!cantDismiss && windowWidth > 600 && (
                                <Close onClick={handleClose}>{customCross || <CrossSvg width={13} />}</Close>
                            )}
                            <Content {...rest}>
                                {title &&
                                    (windowWidth > 600 || mobileNoTransformTitleToPageHeader ? (
                                        <Title noTitleBorder={noTitleBorder}>{title}</Title>
                                    ) : (
                                        <MobilePageHeaderStyled noSlickHeader onBack={handleClose}>
                                            {title}
                                        </MobilePageHeaderStyled>
                                    ))}
                                <ModalContent modalContentNoOverflowAuto={modalContentNoOverflowAuto} theme={theme}>
                                    {children}
                                </ModalContent>
                                {footer && <Footer noFooterBorder={noFooterBorder}>{footer}</Footer>}
                            </Content>
                            {fullModalLoading && (
                                <ModalLoading size={0} column align="center" justify="center">
                                    <FormattedMessage defaultMessage="Loading" />
                                    <LoaderSvg width={50} />
                                </ModalLoading>
                            )}
                        </Container>
                    </motion.div>
                </ModalOverlay>
            )}
        </AnimatePresence>
    )
}

const MobilePageHeaderStyled = styled(MobilePageHeader)`
    margin: -8px 0 16px;
`

const ModalLoading = styled(Space)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.3);
    cursor: progress;
    border-radius: 6px;
    z-index: 5;
`

const Footer = styled.div<{ noFooterBorder?: boolean }>`
    padding-top: 20px;
    border-top: 1px solid var(--light-bg-color);

    &.noFooterBorder {
        border-top: none;
    }

    @media (max-width: 600px) {
        padding-top: 15px;
        padding-bottom: calc(env(safe-area-inset-bottom) - 7px);
    }
`

const ModalContent = styled.div<{ theme?: Theme; modalContentNoOverflowAuto?: boolean }>`
    &:not(.modalContentNoOverflowAuto) {
        overflow: auto;
    }
    padding: 20px 0;
    margin-right: -45px;
    padding-right: 45px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--main-second-text-color);
    }

    &.theme-dark {
        &::-webkit-scrollbar-track {
            background-color: #28344a;
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2281ff;
        border-radius: 8px;
    }

    @media (max-width: 600px) {
        margin: 0 -20px;
        padding: 10px 20px;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`

const ModalOverlay = styled.div<{ theme?: Theme }>`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    &.theme-dark {
        ${ModalLoading} {
            background: rgba(0, 0, 0, 0.3) !important;
        }
    }

    @media (max-width: 600px) {
        align-items: flex-end;
        height: 100svh;
        box-sizing: border-box;
    }
`

const Container = styled.div<{ noXPadding?: boolean; noYPadding?: boolean }>`
    display: flex;
    position: relative;
    max-width: 520px;
    width: 100%;
    max-height: calc(100% - 20px);
    background: var(--bg-color-1);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 35px 45px;
    margin: 0 auto;

    &.noXPadding {
        padding-left: 0 !important;
        padding-right: 0 !important;

        ${ModalContent} {
            margin-right: 0;
            padding-right: 0;
        }
    }

    &.noYPadding {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    @media (max-width: 600px) {
        opacity: 1;
        padding: 30px 20px 0;
        border-radius: 20px 20px 0 0;
        max-height: calc(100% - 20px - env(safe-area-inset-top));
    }
`

const Close = styled.div`
    position: absolute;
    top: 15px;
    right: 18px;
    color: #b8bdc4;
    transition: 0.2s color;
    cursor: pointer;

    &:hover {
        color: #2281ff;
    }

    @media (max-width: 768px) {
        top: 42px;
        right: 45px;
    }

    @media (max-width: 600px) {
        top: 15px;
        right: 20px;

        svg {
            width: 12px !important;
            color: #9ba1aa;
        }
    }
`

const Title = styled.div<{ noTitleBorder?: boolean }>`
    border-bottom: 1px solid var(--light-bg-color);
    padding-bottom: 15px;
    line-height: 1.5;
    font-size: 24px;
    font-weight: bold;

    &.noTitleBorder {
        border-bottom: none;
        padding-bottom: 0;
    }

    @media (max-width: 600px) {
        font-weight: 400;
        font-size: 22px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
`

export default Modal
